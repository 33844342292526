<template>
  <div class="req-temp-container">
    <vs-row>
      <vs-col vs-type="flex" vs-w="12">
        <vs-card class="custom-vx-card">
          <vs-button v-if="hasPermission"
            flat
            v-round
            class="flex primary ml-auto mr-5"
            @click="$router.push({ name: 'onboarding-profile-create' })"
          >
           + New profile
          </vs-button>
          <vs-table :data="onboardingProfiles" class="mt-5">
            <template slot="header">
              <vs-select label="Show records" v-model="limit" class="mb-4">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in limitValue"
                />
              </vs-select>
            </template>
            <template slot="thead">
              <vs-th> Profile ID </vs-th>
              <vs-th> Profile name </vs-th>
              <vs-th> </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.profileId">
                  {{ tr.profileId }}
                </vs-td>
                <vs-td :data="tr.name">
                  {{ tr.name }}
                </vs-td>

                <vs-td :data="tr">
                  <a
                    @click="handleEdit(tr._id)"
                    class="underline cursor-pointer ml-3"
                    >Edit</a
                  >
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="mt-3">
            <v-pagination
              v-model="pageNumber"
              :page-count="totalPage"
            ></v-pagination>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import vPagination from "@/views/components/pagination";

export default {
  components: { vPagination },

  name: "index",
  data(){
    return {
      onboardingProfiles:[],
      pageNumber: 1,
      limit: 50,
      totalData: 0,
      limitValue: [
        { text: "1", value: 1 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      hasPermission: false
    }
  },
  methods:{
    ...mapActions('onboardingProfile',['getOnboardingProfiles']),
    ...mapActions("admin", ["checkLogin"]),
    handleEdit(id){
      this.$router.push({
        name: "onboarding-profile-edit",
        params: { onboardingProfileId: id }
      });
    },
    getAllProfiles(){
      const params = `?page=${this.pageNumber}&limit=${this.limit}&sort=desc`;
      this.getOnboardingProfiles(params).then( res => {
          this.onboardingProfiles = res.data.data.docs;
          this.totalData = res.data.data.pagination.total ? res.data.data.pagination.total : 0;
      });
    },
    async checkAdminLogin() {
      await this.checkLogin().then(res => {
        this.hasPermission = res.data.data.permissions["onboardingProfiles"] || false;
      });
    }
  },
  mounted(){
    this.getAllProfiles()
    this.checkAdminLogin()
  },
  watch: {
    pageNumber() {
      this.getAllProfiles();
    },
    limit() {
      this.getAllProfiles();
      this.pageNumber = 1;
    },
  },
  computed: {
    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },
  },
}
</script>
